@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
}

@media (prefers-color-scheme: dark) {
  :root {
  }
}

body {
  font-family: -apple-system, 'Inter', BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: white;
  color: #333333;
}

li.checkmark {
  background: url(/checkmark.svg) no-repeat 0 9px transparent;
  background-size: 20px;
}

li.chat {
  background: url(/chat.svg) no-repeat 0 9px transparent;
  background-size: 20px;
}

li.calendar {
  background: url(/calendar.svg) no-repeat 0 9px transparent;
  background-size: 20px;
}

li.play {
  background: url(/play.svg) no-repeat 0 9px transparent;
  background-size: 20px;
}

.instagram-social-link {
  position: relative;
}

.instagram-social-link::after {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url('/social-link-share-icon.svg');
  background-size: cover;
  margin-left: 5px;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  transform: translateY(calc(-50% + 1px));
}

#appleid-signin {
  font-size: 10px !important;
}

.google-button-font {
  font-family: "Google Sans",arial,sans-serif !important;
}

.google-button-wrapper:hover .google-button-font {
  box-shadow: none;
  border-color: #d2e3fc;
  outline: none;
  background-color: #F8FAFE;
  cursor: pointer;
  transition: background-color .218s
}

.newie-heading-home {
  margin-left: 90px;
  margin-top: -40px;
  max-width: 270px;
  width: calc(100% - 90px);
}

.home-subtitle {
  font-weight: 600;
  font-size: 16px;
  color: black;
  text-align: center;
  width: 100%;
  z-index: 10;
  padding-top: 55px;
  padding-bottom: 35px;
}

.home-get-the-app {
  font-weight: 600;
  font-size: 16px;
  color: black;
  text-align: center;
  width: 100%;
  z-index: 10;
  padding-bottom: 25px;
}

html {
}

.firebase-emulator-warning {
  display: none;
}
